<template>
	<PaysheetForm @submit="createPaysheet" @cancel="gotoList" />
</template>

<script>
import { GET_TYPES_ORDER } from "../../typeOrder/store/action-types"
import { createNamespacedHelpers, mapState } from "vuex"
import { PARAMS, PAYSHEET_TABLE } from "../../../shared/plugins/constants"
import { GET_EDITORS_NOT_PAYSHEET } from "../../editors/store/action-types"
import { RESET_STATE_EDITOR } from "../../editors/store/mutation-types"
import { RESET_STATE } from "../../typeOrder/store/mutation-types"
import { RESET_STATE_PAYSHEET, SET_EDITOR_SELECTED } from "../store/mutation-types"
import { CREATE_PAYSHEET } from "../store/action-types"
const {
	mapState: typeOrderSate,
	mapActions: typeOrderActions,
	mapMutations: typeOrderMuatations,
} = createNamespacedHelpers("typeOrder")

const {
	mapActions: editorsActions,
	mapState: editorsState,
	mapMutations: editorsMutations,
} = createNamespacedHelpers("editors")

const { mapMutations, mapState: paysheetState, mapActions } = createNamespacedHelpers("paysheet")
export default {
	name: "CreatePaysheet",
	components: {
		PaysheetForm: () => import("../componetns/PaysheetForm"),
	},
	data() {
		return {
			offsetType: PARAMS.OFFSET,
			offsetAll: PARAMS.OFFSET,
			offsetSelected: PARAMS.OFFSET,
		}
	},
	computed: {
		...typeOrderSate(["recentFilters"]),
		...mapState(["pageChanged"]),
		...editorsState({ recentFiltersEditor: "recentFilters" }),
		...paysheetState([
			"recentFiltersSelected",
			"listEditorSelected",
			"isChangePageEditorsSelected",
		]),
	},
	watch: {
		pageChanged(val) {
			if (val) {
				switch (val.tableName) {
					case PAYSHEET_TABLE.WAGE:
						this.offsetType = val.offset
						this.getTypesOrder()
						break
					default:
						break
				}
			}
		},
	},
	created() {
		this.setupData()
	},
	beforeDestroy() {
		this.$store.commit("set", ["pageChanged", null])
		this.RESET_STATE()
		this.RESET_STATE_EDITOR()
		this.RESET_STATE_PAYSHEET()
	},
	methods: {
		...typeOrderActions({ GET_TYPES_ORDER }),
		...editorsActions({ GET_EDITORS_NOT_PAYSHEET }),
		...editorsMutations({ RESET_STATE_EDITOR }),
		...typeOrderMuatations({ RESET_STATE }),
		...mapMutations({ RESET_STATE_PAYSHEET, SET_EDITOR_SELECTED }),
		...mapActions({ CREATE_PAYSHEET }),
		async setupData() {
			await Promise.all[(this.getEditorsNotInPaysheet(), this.getTypesOrder())]
		},
		async getTypesOrder() {
			const params = {
				data: { ...this.recentFilters, offset: this.offsetType },
				notLoading: true,
			}
			await this.GET_TYPES_ORDER(params)
		},
		async getEditorsNotInPaysheet() {
			const params = {
				...this.recentFiltersEditor,
				offset: this.offsetAll,
				limit: 1000,
				isSort: true,
			}
			await this.GET_EDITORS_NOT_PAYSHEET(params)
		},
		gotoList() {
			this.$router.go(-1)
		},
		async createPaysheet(params) {
			await this.CREATE_PAYSHEET(params)
		},
	},
}
</script>

<style></style>
